import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../httpService/httpService";

// Async thunk for fetching notifications
export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/user/fetch-notifications', {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch notifications');
    }
  }
);

// Slice for notifications
const getNotificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    items: [],
    loading: false,
    error: null,
    page: 1,
    limit: 10,
    totalNotifications: 0 ,
    totalPages : 0 ,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload.notifications; // Adjust based on API response structure
        state.totalNotifications = action.payload.totalNotifications ;
        state.totalPages = action.payload.totalPages ;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setPage, setLimit } = getNotificationsSlice.actions;

export default getNotificationsSlice.reducer;
