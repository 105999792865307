import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const deleteCompetitor = createAsyncThunk(
  "competitor/deleteCompetitor",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.delete(
        `/delete-competitor/admin/appsells/${id}`,
        
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const res = response;

        return {
          data: res.data,
          headers: {
            contentType: response.headers["content-type"],
            contentLength: response.headers["content-length"],
          },
        };
      } else {
        // Handle non-success responses
        throw new Error(
          "Add Competitor failed with status: " + response.status
        );
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteCompetitorSlice = createSlice({
  name: "deleteCompetitor",
  initialState: {
    competitor: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetStatusCreate: (state) => {
      state.status = "idle";
      state.error = null;
      state.user = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCompetitor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCompetitor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.competitor = action.payload;
        state.error = null;
      })
      .addCase(deleteCompetitor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetStatusCreate } = deleteCompetitorSlice.actions;

export default deleteCompetitorSlice.reducer;
