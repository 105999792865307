// uploadSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";
// Async thunk for uploading the image
export const uploadVisRepImage = createAsyncThunk(
  "upload/uploadVisRepImage",
  async ({ file, plan_id, site_id }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("plan_id", plan_id);
    formData.append("site_id", site_id);

    const token = localStorage.getItem("authToken");

    try {
      const response = await axiosInstance.post(
        "/visualrep-image-upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Return the error message if the request fails
    }
  }
);
// Slice for managing the upload state for each site
const uploadVisRepImageSlice = createSlice({
  name: "uploadVisRepImage",
  initialState: {},
  reducers: {
    uploadVisRepImageStart: (state, action) => {
      const siteId = action.payload;
      // Set loading to true when the upload starts for a specific site
      state[siteId] = { loading: true, imageUrl: null, error: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadVisRepImage.pending, (state, action) => {
        const siteId = action.meta.arg.site_id;
        state[siteId] = { loading: true, imageUrl: null, error: null ,  uploadStatus: null};
      })
      .addCase(uploadVisRepImage.fulfilled, (state, action) => {
        const { site_id, image_url } = action.payload;
        state[site_id] = { loading: false, imageUrl: image_url, error: null,  uploadStatus: 'success' };
      })
      .addCase(uploadVisRepImage.rejected, (state, action) => {
        const siteId = action.meta.arg.site_id;
        state[siteId] = {
          loading: false,
          imageUrl: null,
          error: action.payload || action.error.message,
          uploadStatus: 'error' 
        };
      });
  },
});
// Export the action so it can be dispatched
export const { uploadVisRepImageStart } = uploadVisRepImageSlice.actions;

// Export the reducer to be used in the store
export default uploadVisRepImageSlice.reducer;
