import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { errorMiddleware } from "./middleware/errorMiddleWare.js";
import storage from "redux-persist/lib/storage";
import signupReducer from "./features/signup/signupSlice";
import clientManagementSlice from "./features/ClientReducer/clientManagementSlice";

import signInSlice from "./features/SignIn/signInSlice";
import sidebarSlice from "./features/sidebarSlice.js";
import createPassworsSlice from "./features/CreatePassword/createPassworsSlice";
import clientSlice from "./features/clientSlice";
import deleteClientSlice from "./features/deleteClientSlice";
import updateClientSlice from "./features/updateClientSlice";
import getUserSlice from "./features/getUserSlice";
import createUserSlice from "./features/createUserSlice";
import getRolesSlice from "./features/getRolesSlice";
import deleteUserSlice from "./features/deleteUserSlice";
import editUserSlice from "./features/editUserSlice";
import activateUserSlice from "./features/activateUserSlice.js";
import fetchAdminMediumsSlice from "./features/fetchAdminMediumsSlice";
import editAdminMediumSlice from "./features/editAdminMediumSlice";
import addMediumSlice from "./features/AdminMediums/addMediumSlice";
import deleteAdminMediumSlice from "./features/AdminMediums/deleteAdminMediumSlice";
import fetchAdminSECSlice from "./features/AdminSEC/fetchAdminSECSlice";
import addAdminSECSlice from "./features/AdminSEC/addAdminSECSlice";
import fetchAdminCitiesSlice from "./features/Cities/fetchAdminCitiesSlice";
import editAdminSecSlice from "./features/AdminSEC/editAdminSecSlice";
import deleteSECSlice from "./features/AdminSEC/deleteSECSlice";
import editAdminCitySlice from "./features/Cities/editAdminCitySlice";
import addAdminCitySlice from "./features/Cities/addAdminCitySlice";
import deleteAdminCitySlice from "./features/Cities/deleteAdminCitySlice";

import fetchPendingBriefSlice from "./features/fetchPendingBrief/fetchPendingBriefSlice";

import fetchApprovedBriefSlice from "./features/fetchApprovedBreif/fetchApprovedBriefSlice";
import fetchRejectedBriefSlice from "./features/fetchRejectedBrief/fetchRejectedBriefSlice";
import fetchClientBriefSlice from "./features/fetchApprovedBreif/fetchNewBriefByClientId";
import recordBriefSlice from "./features/recordBriefSlice";

import acceptBriefSlice from "./features/acceptBrief/acceptBriefSlice";

import rejectBriefSlice from "./features/rejectBreif/rejectBriefSlice";
import deleteBriefSlice from "./features/deleteBriefSlice.jsx";
import approvedPlanSlice from "./features/Plan/approvedPlanSlice";
import fetchPlanSlice from "./features/Plan/fetchPlanSlice.js";
import InProgressPlanSlice from "./features/Plan/InProgressPlanSlice.js";
import recordPlainSlice from "./features/Plan/recordPlainSlice";

import lostPlainSlice from "./features/Plan/lostPlainSlice";

import fetchPOSlice from "./features/Plan/fetchPOSlice";

import approvedPOSlice from "./features/Plan/approvedPOSlice";

import fetchCampaignSlice from "./features/Campaign/fetchCampaignSlice";

import selectedCampaignSlice from "./features/Campaign/selectedCampaignSlice";

import rejectPOSlice from "./features/Plan/rejectPOSlice";

import singleBriefSlice from "./features/singleBriefSlice";

import fetchRegionsSlice from "./features/AddSite/fetchRegionsSlice";
import alterPlanSlice from "./features/Plan/alterPlanSlice";
import siteManagementSlice from "./features/siteManagementSlice";
import addRequestedSiteSlice from "./features/addrequestedSiteSlice.js";
import modificationRequiredSlice from "./features/Plan/modificationRequiredSlice.js";
import modificationPendingSlice from "./features/Plan/modificationPendingSlice.js";
import campaignSlice from "./features/CampaignSlice/campaignSlice";
import OperationAnalystSlice from "./features/OperationAnalystSlice";
import fetchClientCampaignSlice from "./features/Campaign/fetchClientCampaignSlice";
import dashboardSlice from "./features/dashboard/dashboardSlice";
import createPlanSlice from "./features/Plan/createPlanSlice.js";
import tabSlice from "./features/tabSlice";
import fetchAnalysisDataSlice from "./features/PreCampaignAnalysis/fetchAnalysisData.jsx";

// Plan Summary
import fetchConsolidateSummarySlice from "./features/PlanSummary/fetchconsolidatedSummarySlice.js";
import fetchDigitalSummarySlice from "./features/PlanSummary/fetchDigitalSummarySlice.js";
import fetchConventionalSummarySlice from "./features/PlanSummary/fetchConventionalSummarySlice.js";
import fetchBreakUpSummarySlice from "./features/PlanSummary/fetchBreakUpSummarySlice.js";
import fetchFinancialsSummarySlice from "./features/PlanSummary/fetchFinancialsSummarySlice.js";
import fetchFinancialGraphSlice from "./features/PlanSummary/fetchFinancialGraphSlice.js";
import fetchMapDataSlice, {
  fetchMapData,
} from "./features/geographicalSpread/fetchmapdataSlice.js";
import fetchDurationDataSlice from "./features/PlanSummary/fetchDurationDataSlice.js";
import fetchPlanVersion2Slice from "./features/Plan/fetchPlanVersion2.js";
import downloadPDFSlice, {
  downloadPDF,
} from "./features/PlanSummary/downloadPDF.js";
//  Brief counts
import fetchBriefCountSlice from "./features/fetchBriefCount/fetchBriefCountSlice.js";
import fetchPlanCountSlice from "./features/Plan/fetchPlanCountSlice.js";
// For Operation Analyst to uplaod img
import uploadSiteImageSlice from "./features/CampaignSlice/uploadsiteImageSlice.js";
import deleteApprovedImageSlice, {
  deleteApprovedImage,
} from "./features/CampaignSlice/deleteApprovedImageSlice.js";
//  For plan name
import planNameSlice from "./features/Plan/setPlanNameSlice.js";

// For Competitors Report
import fetchCompetitorsSlice from "./features/CompetitorReports/fetchCompetitorsSlice.js";
import fetchCompetitorCampaignsSlice from "./features/CompetitorReports/fetchCompetitorsCampaignSlice.js";
import addCompetitorSlice from "./features/CompetitorReports/addCompetitorSlice.js";
import createCampaignSlice from "./features/CompetitorReports/createCampaignSlice.js";
import deleteCompetitorSlice  from "./features/CompetitorReports/deleteCompetitorSlice.js";
import updateCompetitorSlice  from "./features/CompetitorReports/updateCompetitorSlice.js";
// Competitor Campaign 
import deleteCompetitorCampaignSlice  from "./features/CompetitorReports/deleteCompetitorCampaignSlice.js";
import updateCompetitorCampaignSlice  from "./features/CompetitorReports/updateCompetitorCampaignSlice.js";
import fetchCompetitorSitesSlice from "./features/CompetitorReports/fetchCompetitorSites.js";
import UpdateCompetitorSitesSlice, {
  UpdateCompetitorSites,
} from "./features/CompetitorReports/UpdateCompetitorSites.jsx";
import createReportSlice from "./features/CompetitorReports/createReportSlice.js";
// Reports View
import fetchReportSlice from "./features/CompetitorReports/fetchReportSlice.js";
// For Designer

import uploadDesignSlice from "./features/Design/uploadDesignSlice.js";

import uploadVideoSlice, {
  addVideoLink,
} from "./features/Design/uploadVideoSlice.js";
// Visual Representation Image 
import uploadVisRepImageSlice from "./features/PlanSummary/uploadVisRepImgSlice.js";
// Complete Campaign
import completeCampaignSlice from "./features/CampaignSlice/completeCampaignSlice.js";
import fetchCompletedCampaignSlice from "./features/Campaign/fetchCompletedCampaignSlice.js";

import fetchClientCompletedCampaignSlice from "./features/Campaign/fetchClientCompletedCampaignSlice.js";
import globalErrorReducer from './features/globalErrorSlice.js';

// Pending plan 
import pendingPlansSlice, { fetchPendingPlans } from "./features/Plan/fetchPendingPlanSlice.js";

// For notifications 
import getNotificationsSlice  from "./features/getNotificationsSlice.js";
const rootReducer = combineReducers({
  signup: signupReducer,
  clientMangement: clientManagementSlice,
  signIn: signInSlice,
  createPassword: createPassworsSlice,
  createClient: clientSlice,
  deleteClient: deleteClientSlice,
  updateClient: updateClientSlice,
  getUser: getUserSlice,
  createUser: createUserSlice,
  getRoles: getRolesSlice,
  deleteUser: deleteUserSlice,
  editUser: editUserSlice,
  activateUser: activateUserSlice,
  adminMedium: fetchAdminMediumsSlice,
  editMedium: editAdminMediumSlice,
  addMedium: addMediumSlice,
  deleteMedium: deleteAdminMediumSlice,
  fetchCity: fetchAdminCitiesSlice,
  editCity: editAdminCitySlice,
  addCity: addAdminCitySlice,
  deleteCity: deleteAdminCitySlice,
  fetchSEC: fetchAdminSECSlice,
  addSEC: addAdminSECSlice,
  editSec: editAdminSecSlice,
  deleteSec: deleteSECSlice,
  pendingBreif: fetchPendingBriefSlice,
  approvedBrief: fetchApprovedBriefSlice,
  rejectedBrief: fetchRejectedBriefSlice,
  clientBrief: fetchClientBriefSlice,
  selectedBrief: recordBriefSlice,
  acceptBrief: acceptBriefSlice,
  rejectBrief: rejectBriefSlice,
  approvedPlan: approvedPlanSlice,
  fetchPlan: fetchPlanSlice,
  fetchPlanVersion2: fetchPlanVersion2Slice,
  selectedPlan: recordPlainSlice,
  InProgressPlan: InProgressPlanSlice,
  lostPlan: lostPlainSlice,
  fetchPO: fetchPOSlice,
  approvedPO: approvedPOSlice,
  fetchCampaign: fetchCampaignSlice,
  selectedCampaign: selectedCampaignSlice,
  rejectPO: rejectPOSlice,
  singleBrief: singleBriefSlice,
  fetchRegions: fetchRegionsSlice,
  alterPlan: alterPlanSlice,
  siteManagement: siteManagementSlice,
  campaignDetail: campaignSlice,
  OperationAnalyst: OperationAnalystSlice,
  dashboard: dashboardSlice,
  currentTab: tabSlice,
  clientCampaign: fetchClientCampaignSlice,
  createPlan: createPlanSlice,
  fetchAlterRequired: modificationRequiredSlice,
  fetchAlterPending: modificationPendingSlice,
  fetchAnalysisData: fetchAnalysisDataSlice,
  fetchConsolidateSummary: fetchConsolidateSummarySlice,
  fetchDigitalSummary: fetchDigitalSummarySlice,
  fetchConventionalSummary: fetchConventionalSummarySlice,
  fetchBreakUpSummary: fetchBreakUpSummarySlice,
  fetchFinancialsSummary: fetchFinancialsSummarySlice,
  fetchFinancialGraph: fetchFinancialGraphSlice,
  fetchDurationData: fetchDurationDataSlice,
  fetchMapData: fetchMapDataSlice,
  downloadPDF: downloadPDFSlice,
  fetchBriefCount: fetchBriefCountSlice,
  fetchPlanCount: fetchPlanCountSlice,
  deleteBrief: deleteBriefSlice,
  plan_name: planNameSlice,
  uploadSiteImage: uploadSiteImageSlice,
  deleteApprovedImage: deleteApprovedImageSlice,
  fetchCompetitors: fetchCompetitorsSlice,
  fetchCompetitorCampaigns: fetchCompetitorCampaignsSlice,
  addCompetitorSlice: addCompetitorSlice,
  createCampaignSlice: createCampaignSlice,
  fetchCompetitorSites: fetchCompetitorSitesSlice,
  UpdateCompetitorSites: UpdateCompetitorSitesSlice,
  createReport: createReportSlice,
  addDesignLink: uploadDesignSlice,
  completeCampaign: completeCampaignSlice,
  fetchCompletedCampaign: fetchCompletedCampaignSlice,
  fetchReport: fetchReportSlice,
  addRequestedSite: addRequestedSiteSlice,
  sidebar: sidebarSlice,
  fetchClientCompletedCampaign: fetchClientCompletedCampaignSlice,
  addVideoLink: uploadVideoSlice,
  fetchPendingPlans : pendingPlansSlice ,
  globalError: globalErrorReducer,
  uploadVisRepImage : uploadVisRepImageSlice ,
  deleteCompetitor : deleteCompetitorSlice,
  updateCompetitor : updateCompetitorSlice,
  deleteCompetitorCampaign : deleteCompetitorCampaignSlice ,
  updateCompetitorCampaign : updateCompetitorCampaignSlice ,
  getNotifications : getNotificationsSlice ,
});


const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "selectedBrief",
    "selectedPlan",
    "fetchPO",
    "selectedCampaign",
    "signIn",
    "sidebar",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(errorMiddleware),
});

export const persistor = persistStore(store);
