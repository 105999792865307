import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Async thunk for deleting a competitor campaign
export const deleteCompetitorCampaign = createAsyncThunk(
  "competitor/deleteCompetitorCampaign",
  async ({ competitor_id, competitor_campaign_id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.delete(
        `/delete-competitor-campaign/admin/appsells`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            competitor_id,
            competitor_campaign_id,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        return {
          data: response.data,
          headers: {
            contentType: response.headers["content-type"],
            contentLength: response.headers["content-length"],
          },
        };
      } else {
        throw new Error(
          `Delete Competitor Campaign failed with status: ${response.status}`
        );
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred while deleting"
      );
    }
  }
);

const deleteCompetitorCampaignSlice = createSlice({
  name: "deleteCompetitorCampaign",
  initialState: {
    competitor: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetDeleteStatus: (state) => {
      state.competitor = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCompetitorCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCompetitorCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.competitor = action.payload;
        state.error = null;
      })
      .addCase(deleteCompetitorCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDeleteStatus } = deleteCompetitorCampaignSlice.actions;

export default deleteCompetitorCampaignSlice.reducer;
