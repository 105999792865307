// src/features/competitor/updateCompetitorSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Async thunk for updating a competitor
export const updateCompetitor = createAsyncThunk(
  "competitor/updateCompetitor",
  async ({ id, competitor_name, file }, { rejectWithValue }) => {
    console.log('id : ' + JSON.stringify(id)) ;
    try {
      const token = localStorage.getItem("authToken");

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("competitor_name", competitor_name);
      if (file) {
        formData.append("file", file);
      }

      const response = await axiosInstance.put(
        `/update-competitor/admin/appsells/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        return {
          data: response.data,
          headers: {
            contentType: response.headers["content-type"],
            contentLength: response.headers["content-length"],
          },
        };
      } else {
        throw new Error(
          `Update Competitor failed with status: ${response.status}`
        );
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred while updating"
      );
    }
  }
);

const updateCompetitorSlice = createSlice({
  name: "updateCompetitor",
  initialState: {
    competitor: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetUpdateStatus: (state) => {
      state.competitor = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCompetitor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCompetitor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.competitor = action.payload;
        state.error = null;
      })
      .addCase(updateCompetitor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetUpdateStatus } = updateCompetitorSlice.actions;

export default updateCompetitorSlice.reducer;
