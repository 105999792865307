import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFU8jrHsduIq75onUeWdDi3IvxgecOPDo",
  authDomain: "appsells2.firebaseapp.com",
  projectId: "appsells2",
  storageBucket: "appsells2.firebasestorage.app",
  messagingSenderId: "390367627580",
  appId: "1:390367627580:web:ccb8a93de099a06a043639",
  measurementId: "G-4BGVJHFM0P",
  // vapidKey: process.env.REACT_APP_VAPID_KEY,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
// Continuous message listener
export const onMessageListener = (callback) => {
  onMessage(messaging, (payload) => {
    callback(payload); 
  });
};

export { messaging, onMessage };
