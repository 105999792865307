import { getToken } from "firebase/messaging";
import { messaging } from "./firebaseConfig";

const requestPermissionAndGetToken = async () => {
  try {
    // Request permission to send notifications
    const permission = await Notification.requestPermission();
    console.log('key : ' + process.env.REACT_APP_VAPID_KEY) ;

    if (permission === "granted") {
      // Get the FCM token
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,  // VAPID key from Firebase Cloud Messaging settings
      });
      if (currentToken) {
        console.log("FCM Token:", currentToken);
        return currentToken; // You can save the token in your app state or backend
      } else {
        console.error("No FCM token available");
      }
    } else {
      console.error("Notification permission denied");
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

export default requestPermissionAndGetToken;
