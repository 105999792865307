import axios from "axios";

const API_BASE_URL = "https://appsellapi.thecbt.live/api";
// const API_BASE_URL = "http://192.168.100.36:3001/api";
// const API_BASE_URL = "http://localhost:3001/api";
// const API_BASE_URL = 'https://appsellsapiv2.thecbt.live/api';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
});

export default axiosInstance;
